.link {
  display: inline;
  color: var(--marketplaceColor);
  font-weight: var(--fontWeightSemiBold);

  /* fallback option */
  word-break: break-all;
  /* use break-word if available */
  word-break: break-word;
  hyphens: auto;
}
