@import '../../../../styles/customMediaQueries.css';

.root {
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  width: 100%;
  position: relative;

  &:nth-of-type(odd) {
    background-color: var(--colorWhite);
  }

  &:target {
    scroll-margin-top: var(--topbarHeight);
    @media (--viewportMedium) {
      scroll-margin-top: var(--topbarHeightDesktop);
    }
  }
}

.sectionContent {
  padding: 32px 0;
  position: relative;
  word-break: break-word;

  @media (--viewportMedium) {
    padding: 64px 0;
  }
}

.backgroundImageWrapper {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

& :global(#how-it-works-for-renters) {

  & [class*="SectionBuilder_blockContainer"] {
    display: block;

    @media (--viewportLarge) {
      display: grid;
    }
  }
}

:global(#hero) {
  position: relative;

  & [class*="SectionBuilder_blockContainer"] {
    padding: 20px 14px;
    margin: 0;

    @media (--viewportLargeWithPaddings) {
      padding: 0 32px 0 32px;
      margin: 0 auto;
    }
  }

  & [class*="SectionContainer_sectionContent"] {
    padding: 20px 0 64px;
    display: flex;
    flex-direction: column;

    @media (--viewportLargeWithPaddings) {
      padding: 0 32px 0 32px;
      display: block;
    }
  }

  & [class*="AspectRatioWrapper_aspectPadding"] {
    padding-bottom: 0 !important;

    @media (--viewportLargeWithPaddings) {
      padding-bottom: 53%!important;
    }
  }

  & [class*="AspectRatioWrapper_aspectBox"] {
    position: static;

    @media (--viewportLargeWithPaddings) {
      position: absolute;
    }
  }
}

:global(#featured-services) {
  background-color: var(--colorWhite);
  & h2 {
    color: var(--marketplaceColor);
    font-weight: var(--fontWeightBold);
    font-size: 36px;
  }

  & [class*="SectionBuilder_description"] {
    font-weight: var(--fontWeightSemiBold);
    max-width: 100%;
    font-size: 20px;
  }

  & [class*="P_p"] {
    font-size: 18px;
  }
}

:global(#featured-services-slider) {
  & [class*="SectionCarousel_block"] {
    position: relative;
    @media (--viewportMedium) {
      /*width: calc((min(var(--contentMaxWidthPages), var(--carouselWidth)) - 32px) / 3);*/
    }
  }

  & [class*="SectionBuilder_ctaButton"] {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    width: 100%;
    height: 100%;
    background: transparent;
    color: transparent;
    box-shadow: none;
  }

  & [class*="Image_fieldImage"] {
    border-radius: var(--borderRadiusBig);
  }

  & [class*="Heading_h3"] {
    color: var(--marketplaceColor);
    font-weight: var(--fontWeightBold);
    position: relative;

    &:after {
      content: '';
      position: absolute;
      height: 20px;
      width: 20px;
      right: 0;
      top: 50%;
      transform: translateY(-50%);
      background: transparent url("./images/majesticons_arrow-left.svg") center center no-repeat;

    }
  }
}

:global(#how-it-works) {
  text-align: center;

  & h2 {
    text-align: center;
    color: var(--marketplaceColor);
    font-weight: var(--fontWeightBold);
    justify-self: center;
    font-size: 36px;
  }

  & h3 {
    text-align: center;
    color: var(--marketplaceColor);
    font-weight: var(--fontWeightBold);
  }

  & [class*="SectionBuilder_description"] {
    font-weight: var(--fontWeightSemiBold);
    text-align: center;
    font-size: 20px;
  }

  & [class*="P_p"] {
    text-align: center;
    font-size: 18px;
  }

}

:global(#how-it-works-search) {
  & h3 {
    position: relative;
    padding-top: 70px;
    &:before {
      content: '';
      background-image: url("./images/search.svg");
      background-repeat: no-repeat;
      background-position: center center;
      width: 64px;
      height: 64px;
      position: absolute;
      top: 0;
      left: 50%;
      transform: translateX(-50%);
    }
  }
}

:global(#how-it-works-connect) {
  & h3 {
    position: relative;
    padding-top: 70px;
    &:before {
      content: '';
      background-image: url("./images/connect.svg");
      background-repeat: no-repeat;
      background-position: center center;
      width: 64px;
      height: 64px;
      position: absolute;
      top: 0;
      left: 50%;
      transform: translateX(-50%);
    }
  }
}

:global(#how-it-works-book) {
  & h3 {
    position: relative;
    padding-top: 70px;
    &:before {
      content: '';
      background-image: url("./images/book.svg");
      background-repeat: no-repeat;
      background-position: center center;
      width: 64px;
      height: 64px;
      position: absolute;
      top: 0;
      left: 50%;
      transform: translateX(-50%);
    }
  }
}

:global(#what-you-need) {
  & h2 {
    color: var(--marketplaceColor);
    font-weight: var(--fontWeightBold);
    text-align: center;
    font-size: 36px;
  }

  & p {
    font-weight: var(--fontWeightSemiBold);
    text-align: center;
    font-size: 20px;
  }

  & [class*="Link_link"] {
    font-weight: var(--fontWeightBold);
    line-height: 60px;
    color: var(--colorWhite);
    background: var(--marketplaceColor);
    border-radius: var(--borderRadiusBig);
    min-width: 300px;
    height: 60px;
    margin: 24px auto;
    text-align: center;
    padding: 0;
    box-shadow: none;

    &:hover {
      background: var(--marketplaceColorDark);
      box-shadow: 0 8px 16px 0 rgb(0 0 0 / 20%);
    }
  }
}

:global(#find-services) {

  & [class*="SectionContainer_sectionContent"] {
    padding: 0;
  }

  & [class*="SectionBuilder_blockContainer"] {
    max-width: 100%;
    padding: 0;
  }

  & [class*="BlockDefault_text"] {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
    padding: 24px;

    @media (--viewportLarge) {
      align-items: flex-start;
      padding: 0;
    }
  }

  & [class*="Image_fieldImage"] {
    border-radius: 0;
  }

  & h2 {
    font-size: 24px;
    color: var(--marketplaceColor);
    font-weight: var(--fontWeightBold);
    word-break: auto-phrase;

    @media (--viewportLarge) {
      font-size: 36px;
    }

    @media (--viewportLargeWithPaddings) {
      max-width: 60%;
    }
  }

  & p {
    font-weight: var(--fontWeightSemiBold);
    font-size: 15px;
    margin: 0;
    text-align: center;

    @media (--viewportLarge) {
      font-size: 18px;
      text-align: left;
    }

    @media (--viewportLargeWithPaddings) {
      max-width: 60%;
    }
  }

  & [class*="Link_link"] {
    font-weight: var(--fontWeightBold);
    text-align: center;
    color: var(--colorWhite);
    width: 300px;
    height: 60px;
    border-radius: var(--borderRadiusBig);
    line-height: 60px;
    padding: 0 20px;
    margin: 5px 0;
    box-shadow: none;

    &:hover {
      box-shadow: 0 8px 16px 0 rgb(0 0 0 / 20%);
    }

    @media (--viewportLarge) {
      margin-top: 20px;
    }
  }

}

:global(#footer) {
  & [class*="SectionContainer_sectionContent"] {
    padding: 6px 0;
  }

  & [class*="SectionFooter_icons"] {
    svg {
      fill: var(--textColor);
    }
  }

}



